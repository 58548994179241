import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {STATUS_LABELS} from 'configs';
import {IconFA} from 'components/Icons';

const propTypes = {
  status: PropTypes.string,
  icon: PropTypes.bool,
};
const defaultProps = {
  tag: 'span',
  icon: true,
  color: 'muted',
};

function LabelStatus(props) {
  const {tag, icon, color, className, status} = props;
  const _className = classNames(styles['label-status'], {
    [className]: !!className,
  });
  const _classNameIcon = classNames('small mr-1', {
    [`text-${color}`]: !!color,
  });
  const _status = STATUS_LABELS[status] || 'Unknown Status';
  const _icon = icon && <IconFA name="circle" className={_classNameIcon} />;

  return React.createElement(
    tag,
    {className: _className},
    <React.Fragment>
      {_icon}
      {_status}
    </React.Fragment>
  );
}

LabelStatus.propTypes = propTypes;
LabelStatus.defaultProps = defaultProps;

export default LabelStatus;
