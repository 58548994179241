import {Badge} from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

import {PARTICIPATION_STATUS_LABELS} from 'configs';

const LabelParticipation = (props) => {
  const {status, color, large, className, ...rest} = props;
  const _status = PARTICIPATION_STATUS_LABELS[status] || 'Unknown Status';
  return (
    <Badge
      color={color}
      className={classNames(styles['participation-status-badge'], className)}
      {...rest}
    >
      {_status}
    </Badge>
  );
};

export default LabelParticipation;
