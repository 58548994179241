import urljoin from 'url-join';

export const RELEASE_NOTES_BASE_URL =
  'https://support.iafcertsearch.org/iaf-certsearch-releases/system-updates/iaf-certsearch-release-notes';

const RELEASE_NOTES = {
  feb9: urljoin(
    RELEASE_NOTES_BASE_URL,
    'iaf-certsearch-release-february-9-2024'
  ),
  feb14: urljoin(
    RELEASE_NOTES_BASE_URL,
    'iaf-certsearch-release-february-14-2024'
  ),
  feb20: urljoin(
    RELEASE_NOTES_BASE_URL,
    'iaf-certsearch-release-february-20-2024'
  ),
  march: urljoin(
    RELEASE_NOTES_BASE_URL,
    'iaf-certsearch-release-march-26-2024'
  ),
  july22: urljoin(
    RELEASE_NOTES_BASE_URL,
    'iaf-certsearch-release-july-22-2024'
  ),
  soon: urljoin(RELEASE_NOTES_BASE_URL, 'iaf-certsearch-release-coming-soon'),
};

export default RELEASE_NOTES;
