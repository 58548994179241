import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {keys} from 'lodash/object';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {
  IMAGE_WIDTHS,
  DEFAULT_AB_LOGO,
  DEFAULT_CB_LOGO,
  DEFAULT_CE_LOGO,
  DEFAULT_COMPANY_LOGO,
  COMPANY_TYPES,
} from 'configs';

import Image from './Image';

class Component extends React.Component {
  static propTypes = {
    bordered: PropTypes.bool,
    companyType: PropTypes.oneOf(keys(COMPANY_TYPES)),
    file: PropTypes.string,
    size: PropTypes.oneOf(keys(IMAGE_WIDTHS)),
  };

  static defaultProps = {
    size: 'md',
    bordered: false,
  };

  get className() {
    const {className, size, bordered} = this.props;

    return classNames(
      styles['image-company-logo'],
      {
        [styles[`image-company-logo--${size}`]]: size,
        [styles[`image-company-logo--bordered`]]: bordered,
      },
      className
    );
  }

  get defaultSrc() {
    let defaultSrc;

    const {companyType, size} = this.props;

    switch (companyType) {
      case COMPANY_TYPES.ab:
        defaultSrc = DEFAULT_AB_LOGO[size];
        break;

      case COMPANY_TYPES.cb:
        defaultSrc = DEFAULT_CB_LOGO[size];
        break;

      case COMPANY_TYPES.ce:
        defaultSrc = DEFAULT_CE_LOGO[size];
        break;

      default:
      case COMPANY_TYPES.company:
        defaultSrc = DEFAULT_COMPANY_LOGO[size];
        break;
    }

    return defaultSrc;
  }

  render() {
    const {file, src, to} = this.props;

    return React.createElement(
      to ? Link : 'span',
      {to: to, className: this.className},
      <Image size="origin" src={src} file={file} defaultSrc={this.defaultSrc} />
    );

    /*return (
      <span className={this.className}>
        <Image
          size="origin"
          src={src}
          file={file}
          defaultSrc={this.defaultSrc}
        />
      </span>
    );*/
  }
}

export default Component;
