import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {VIEWPORT_WIDTHS} from 'configs';

import {useViewport} from 'contexts/ViewportContext';
import {
  Nav,
  NavItem,
  NavLink as NavItemLink,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import {IconFA} from 'components/Icons';
import cx from 'classnames';
import styles from './styles.module.scss';

PublicNav.defaultProps = {
  dark: false,
};

function PublicNav(props) {
  const {dark} = props;
  const {size} = useViewport();

  const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS.xl;

  const navItems = [
    {
      to: '/',
      text: 'Home',
    },
    {
      to: '/about/iaf-certsearch',
      text: 'About',
    },
    {
      to: '/about/iaf',
      text: 'International Accreditation Forum',
    },
    {
      to: '/search/accreditation-bodies',
      text: 'Accreditation Bodies',
    },
    {
      to: '/search/certification-bodies',
      text: 'Certification Bodies',
    },
    {
      to: '/about/iaf-badge',
      text: 'IAF CertSearch Mark',
    },
    {
      to: '/contact',
      text: 'Contact',
    },
  ];

  return (
    <>
      {isMobile ? (
        <UncontrolledDropdown className="ml-auto">
          <DropdownToggle
            color={dark ? 'light' : 'primary'}
            className="rounded"
            outline
          >
            <IconFA name="bars" />
          </DropdownToggle>

          <DropdownMenu right>
            {navItems.map((item, key) => {
              const {to, text} = item;
              return (
                <DropdownItem
                  key={key}
                  tag={NavLink}
                  to={to}
                  exact
                  activeClassName={styles['dropdown__link--active']}
                >
                  {text}
                </DropdownItem>
              );
            })}

            <DropdownItem divider />

            <div className="px-2">
              <Button
                tag={Link}
                color="primary"
                outline
                to="/login"
                className="rounded"
                block
              >
                Log In
              </Button>

              <Button
                tag={Link}
                color="primary"
                to="/register"
                className="rounded"
                block
              >
                Sign Up
              </Button>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <>
          <Nav navbar className="mx-auto">
            {navItems.map((item, key) => {
              const {to, text} = item;
              return (
                <NavItem key={key}>
                  <NavItemLink
                    tag={NavLink}
                    exact
                    to={to}
                    className={cx('text-decoration-none', {
                      [styles['navigation__link--dark']]: dark,
                      [styles['navigation__link--light']]: !dark,
                    })}
                    activeClassName={cx({
                      [styles['navigation__link--dark--active']]: dark,
                      [styles['navigation__link--light--active']]: !dark,
                    })}
                  >
                    {text}
                  </NavItemLink>
                </NavItem>
              );
            })}
          </Nav>

          <Nav navbar>
            <NavItem>
              <Button
                tag={Link}
                outline
                to="/login"
                color={dark ? 'light' : 'primary'}
                className="rounded"
              >
                Log In
              </Button>
            </NavItem>

            <NavItem className="ml-2">
              <Button
                tag={Link}
                to="/register"
                color={dark ? 'light' : 'primary'}
                className="rounded"
              >
                Sign Up
              </Button>
            </NavItem>
          </Nav>
        </>
      )}
    </>
  );
}

export default PublicNav;
