import React, {useEffect} from 'react';
import {useLocation, useRouteMatch} from 'react-router';

import classNames from 'classnames';
import styles from './styles.module.scss';

import {VIEWPORT_WIDTHS} from 'configs';
import useGetRoutes from 'hooks/useGetRoutes';

import {withPage} from 'contexts/PageContext';
import {withViewport} from 'contexts/ViewportContext';

const defaultProps = {
  side: 'left',
};
function Component(props) {
  const {side, showAt, width, children, viewport, page} = props;
  const {isOpenLeftSidebar, setSidebar} = page;
  const {size} = viewport;

  const location = useLocation();
  const allRoutes = useGetRoutes();
  const isPrivate = useRouteMatch(allRoutes);

  const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS[showAt];
  const isOverlayClosed = isMobile && !isOpenLeftSidebar;

  useEffect(() => {
    if (!isMobile && !isOverlayClosed) {
      setSidebar('both', false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (location) setSidebar('both', false);
  }, [location]);

  return (
    <aside
      style={{width: width}}
      className={classNames(styles['page__sidebar'], {
        [styles[`page__sidebar--${side}`]]: !!side,
        [styles[`page__sidebar--overlay`]]: isMobile,
        [styles[`page__sidebar--overlay--closed`]]: isOverlayClosed,
      })}
    >
      <div
        style={{width: width}}
        className={classNames(styles['page__sidebar__inner'], {
          [styles['page__sidebar__inner--private']]: isPrivate && !isMobile,
        })}
      >
        {children}
      </div>
    </aside>
  );
}

Component.defaultProps = defaultProps;

export default withPage(withViewport(Component));
