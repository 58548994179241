import styles from './styles.module.scss';
import React from 'react';
import {Link} from 'react-router-dom';
import {isEmpty} from 'lodash/lang';
import classNames from 'classnames';

import {Button, DropdownToggle, DropdownMenu, Dropdown} from 'reactstrap';
import {LabelDate} from 'components/Labels';
import {IconFA} from 'components/Icons';
import {LoadingPage} from 'components/Loading';

import {
  NotificationProvider,
  NotificationConsumer,
} from 'contexts/NotificationContext';

function getNotificationProperties(code, details = {}) {
  let message, link, iconBg, icon;
  const {
    is_cert_upload,
    error_count,
    skipped_cert_count,
    uploaded_cert_count,
    file_name,
  } = details;

  switch (code) {
    default:
    case 'batch_error': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data upload failed';
      icon = 'fa-exclamation-triangle';
      iconBg = 'notification__icon--error';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_validated': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data has been validated';
      icon = 'fa-check-square';
      iconBg = 'notification__icon--success';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_partially_imported': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data has been partially imported';
      icon = 'fa-check-square';
      iconBg = 'notification__icon--warning';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_ftp_error': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data upload failed';
      icon = 'fa-exclamation-triangle';
      iconBg = 'notification__icon--error';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    // BE should not send this code, this should be only for the old entries of this code that is unread
    case 'batch_ftp_validated': {
      const {dm_upload_batch_id} = details;

      message = (
        <>
          Import using FTP Server <b className="text-success">validated</b>
        </>
      );
      icon = 'fa-check-square';
      iconBg = 'notification__icon--success';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_ftp_partially_imported': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data has been partially imported';
      icon = 'fa-check-square';
      iconBg = 'notification__icon--warning';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    // BE should not send this code, this should be only for the old entries of this code that is unread
    case 'file_upload_success':
      if (isEmpty(details) || !is_cert_upload) {
        message = (
          <>
            Certification Body Data has been{' '}
            <b className="text-primary">uploaded</b>
          </>
        );
        link = '/cb-upload/activity';
      } else {
        message = `${file_name} - ${uploaded_cert_count} certifications has been successfully uploaded`;
        link = null;
      }
      icon = 'fa-check-square';
      iconBg = 'notification__icon--success';
      break;
    case 'file_upload_error':
      if (isEmpty(details) || !is_cert_upload) {
        message = 'Certification Body data upload failed';
        link = '/cb-upload/activity';
      } else {
        // BE should not send this scenario this should be only for the old entries of this code that is unread
        message = `${file_name} - Failed to upload all certifications. ${uploaded_cert_count} uploaded; ${skipped_cert_count} skipped with ${error_count} errors`;
        link = null;
      }
      icon = 'fa-exclamation-triangle';
      iconBg = 'notification__icon--error';
      break;
    // BE should not send this code this should be only for the old entries of this code that is unread
    case 'ftp_file_upload_success':
      if (isEmpty(details) || !is_cert_upload) {
        message = (
          <>
            Certification Body Data has been{' '}
            <b className="text-primary">fetched</b>
          </>
        );
        link = '/cb-upload/activity';
      } else {
        message = `${file_name} - ${uploaded_cert_count} certifications has been successfully uploaded through FTP`;
        link = null;
      }
      icon = 'fa-check-square';
      iconBg = 'notification__icon--success';
      break;
    case 'ftp_file_upload_error':
      if (isEmpty(details) || !is_cert_upload) {
        message = 'Certification Body data upload failed';
        link = '/cb-upload/activity';
      } else {
        // BE should not send this scenario this should be only for the old entries of this code that is unread
        message = `${file_name} - Failed to upload all certifications through FTP. ${uploaded_cert_count} uploaded; ${skipped_cert_count} skipped with ${error_count} errors`;
        link = null;
      }
      icon = 'fa-exclamation-triangle';
      iconBg = 'notification__icon--error';
      break;
    case 'two_fa_enabled':
      icon = 'fa-lock';
      iconBg = 'notification__icon--enabled';
      message = '2FA Authentication is enabled.';
      link = '/settings/security';
      break;
    case 'two_fa_disabled':
      icon = 'fa-unlock';
      iconBg = 'notification__icon--disabled';
      message = '2FA Authentication is disabled.';
      link = '/settings/security';
      break;
    case 'update_password':
      icon = 'fa-key';
      iconBg = 'notification__icon--password';
      message = 'Password has been updated.';
      link = '/settings/security';
      break;
    case 'certificate_update':
      icon = 'fa-folder-open';
      iconBg = 'notification__icon--cert';
      message = `Certification has been updated`;
      link = '/certifications';
      break;
    case 'watchlist_certificate_update':
      icon = 'fa-folder-open';
      iconBg = 'notification__icon--cert';
      message = 'Watched Certificate has been updated';
      link = '/watch-list';
      break;
    case 'company_updated':
      icon = 'fa-building';
      iconBg = 'notification__icon--company';
      message = 'Update Company';
      link = '/';
      break;
    case 'enquiry':
      icon = 'fa-envelope';
      iconBg = 'notification__icon--enquiry';
      message = 'New message on your enquiry';
      link = '/enquiries';
      break;
    case 'enquiry_reply':
      icon = 'fa-envelope';
      iconBg = 'notification__icon--enquiry';
      message = 'New reply on your enquiry';
      link = '/enquiries';
      break;
  }

  return {
    message: message,
    link: link,
    iconBg: iconBg,
    icon: icon,
  };
}

function NotificationItem({notification, onClick}) {
  const {code, status, created_at, details} = notification;

  const {message, link, iconBg, icon} = getNotificationProperties(
    code,
    details
  );

  const className = classNames(styles['notifications__item'], {
    [styles['notifications__item--read']]: status === 'read',
    [styles['notifications__item--unread']]: status === 'unread',
  });

  return (
    <div className={className} onClick={() => onClick(link)}>
      <div className={classNames(styles['notification__icon'], styles[iconBg])}>
        <i
          className={classNames(
            `fa fa-lg ${icon}`,
            styles['notification__icon--text']
          )}
        />
      </div>
      <div className={styles[`notifications__text`]}>
        <div className={styles['notifications__text--message']}>{message}</div>
        <div className={styles['notifications__text--date']}>
          <LabelDate timestamp={created_at} format="fromNow" />
        </div>
      </div>
    </div>
  );
}

function PrivateNavNotification(props) {
  const {dark} = props;

  return (
    <NotificationProvider>
      <NotificationConsumer>
        {({
          isOpen,
          isFetching,
          isFetchingMore,
          data,
          count,
          nextKey,
          handleFetchNotifications,
          handleClick,
          handleToggle,
        }) => {
          return (
            <Dropdown isOpen={isOpen} toggle={handleToggle} nav>
              <DropdownToggle
                nav
                color="transparent"
                className="position-relative mr-3"
              >
                <IconFA
                  name="bell-o fa-lg"
                  className={dark ? 'text-light' : 'text-body'}
                />

                {count > 0 && (
                  <span className={styles['notifications__badge']}>
                    {count > 99 ? '99+' : count}
                  </span>
                )}
              </DropdownToggle>

              <DropdownMenu right className={styles['notifications__list']}>
                {isFetching && <LoadingPage isLoading={isFetching} />}

                {!isFetching && isEmpty(data) && (
                  <div className={styles['notifications__item--empty']}>
                    <div className="mb-1">
                      <IconFA name="inbox" /> No messages
                    </div>

                    <Link to="/settings/notification">
                      Your notification preferences..
                    </Link>
                  </div>
                )}

                {!isFetching &&
                  !isEmpty(data) &&
                  data.map((item, index) => {
                    return (
                      <NotificationItem
                        key={index}
                        notification={item}
                        onClick={(link) =>
                          handleClick(item.status, item.notification_id, link)
                        }
                      />
                    );
                  })}

                {!isFetching && nextKey && (
                  <Button
                    color="link"
                    onClick={handleFetchNotifications}
                    block
                    disabled={isFetchingMore}
                  >
                    Load more
                  </Button>
                )}
              </DropdownMenu>
            </Dropdown>
          );
        }}
      </NotificationConsumer>
    </NotificationProvider>
  );
}

export default PrivateNavNotification;
