import urlJoin from 'url-join';
import {CDN_FILE_URL} from 'configs/Environment';
import {isArray, isBoolean} from 'lodash';

export const VIDEO_GUIDE = {
  introduce_iaf: 'https://youtu.be/P2pEwXVrfUg',
  activate_ab: 'https://youtu.be/5Az_EMLbvzY',
  activate_cb: 'https://youtu.be/2V5yJKPPVGM',
  add_cb: 'https://youtu.be/94FKWGcbJZ8',
  add_standard: 'https://youtu.be/mDEFvD63o00',
  file_upload: 'https://youtu.be/zvO_9HvnJ-A',
  ftp_access: 'https://youtu.be/lzT_75T3_UQ',
  data_mapping: 'https://youtu.be/8dE0nBU29_E',
};

export const DOC_GUIDE = {
  accounts:
    'https://developer.iafcertsearch.org/general-instructions/p/accounts',
  cbs: 'https://developer.iafcertsearch.org/general-instructions/p/certification-bodies',
  certs:
    'https://developer.iafcertsearch.org/general-instructions/p/certifications',
  ces: 'https://developer.iafcertsearch.org/general-instructions/p/certified-entities',
  standards:
    'https://developer.iafcertsearch.org/general-instructions/p/standards',
  users: 'https://developer.iafcertsearch.org/general-instructions/p/users',
  settings:
    'https://developer.iafcertsearch.org/general-instructions/p/settings',
};

export const DEV_GUIDE = {
  file_upload: 'https://developer.iafcertsearch.org/file-upload',
  ftp_access: 'https://developer.iafcertsearch.org/ftp-access',
  api_access: 'https://developer.iafcertsearch.org/api-access',
  data_mapping: 'https://developer.iafcertsearch.org/data-mapping',
  ab_link:
    'https://developer.iafcertsearch.org/field-names/accreditation-body-acronyms',
};

const ASSETS_FOLDER = urlJoin(CDN_FILE_URL, '/assets/t');
const FILE_EXTENSIONS = {xlsx: 'excel', xml: 'xml'};

const filenameConverter = (filename) => {
  const [name, extension] = filename.split('.');
  const newName = FILE_EXTENSIONS[extension] || extension;

  return `${name}_${newName}`;
};
const filenameExt = (filename) => filename.slice(filename.lastIndexOf('.') + 1);
const filenameAccount = (filename) => filename.split('_')[0];

// CB Templates
const CB_TEMPLATES_FILENAME = [
  'ab_cbs.xlsx',
  'ab_cbs_sample.xlsx',
  'ab_cbs.xml',
  'ab_cbs_sample.xml',

  // instructions
  'ab_cbs_instructions.xlsx',
  'ab_cbs_instructions.xml',
];
// Cert Templates
const AB_CERT_TEMPLATES_FILENAME = [
  'ab_certs.xlsx',
  'ab_certs_sample.xlsx',
  'ab_certs.xml',
  'ab_certs_sample.xml',
];
const CB_CERT_TEMPLATES_FILENAME = [
  'cb_certs.xlsx',
  'cb_certs_sample.xlsx',
  'cb_certs.xml',
  'cb_certs_sample.xml',
];
const MNCB_CERT_TEMPLATES_FILENAME = [
  'mncb_certs.xml',
  'mncb_certs_sample.xml',
];

// const CERT_TEMPLATES_FILENAME = [
//   ...AB_CERT_TEMPLATES_FILENAME,
//   ...CB_CERT_TEMPLATES_FILENAME,
//   ...MNCB_CERT_TEMPLATES_FILENAME,
// ];

const templateCreator = (
  version,
  filenames = [],
  urlPrefix,
  recommended,
  description
) => {
  return filenames.map((filename) => ({
    version: version,
    accountType: filenameAccount(filename), // ab_cbs.xlsx -> ab
    fileType: FILE_EXTENSIONS[filenameExt(filename)], // ab_cbs.xlsx -> excel
    name: filenameConverter(filename), // ab_cbs.xlsx -> ab_cbs_excel
    url: !!urlPrefix
      ? urlJoin(ASSETS_FOLDER, urlPrefix, version, filename)
      : urlJoin(ASSETS_FOLDER, version, filename),
    sample: !!filename.includes('sample'),
    recommended: recommended,
    description: description,
    instructions: !!filename.includes('instructions'),
  }));
};

const CB_TEMPLATES = [
  ...templateCreator(
    '2.0',
    CB_TEMPLATES_FILENAME,
    'ab',
    true,
    'The Certification Body Template Version 2.0 allows the assigning of Technical Sectors and Country / Economy under Standards and Schemes.'
  ),
  ...templateCreator(
    '1.1',
    CB_TEMPLATES_FILENAME,
    'ab',
    false,
    'The Certification Body Template Version 1.1. will allow support the assigning of Accreditation Status of your Technical Sectors and Economy'
  ),
  ...templateCreator(
    '1.0',
    CB_TEMPLATES_FILENAME,
    'ab',
    false,
    'The Certification Body Template Version 1.0 is the initial template the system releases.'
  ), // old version
];

const CERT_TEMPLATES = [
  ...templateCreator(
    '2.1',
    AB_CERT_TEMPLATES_FILENAME,
    'ab',
    true,
    'The Certification Template Version 2.1 allows the assigning of Technical Sectors in the certification level.'
  ),
  ...templateCreator(
    '2.1',
    CB_CERT_TEMPLATES_FILENAME,
    'cb',
    true,
    'The Certification Template Version 2.1 allows the assigning of Technical Sectors in the certification level.'
  ),
  ...templateCreator(
    '2.1',
    MNCB_CERT_TEMPLATES_FILENAME,
    'cb',
    true,
    'The Certification Template Version 2.1 allows the assigning of Technical Sectors in the certification level.'
  ),
  ...templateCreator(
    '2.0',
    AB_CERT_TEMPLATES_FILENAME,
    'ab',
    false,
    'The Certification Template Version 2.0 includes the additional of Business Registered Address details and updated Certification Sites information.'
  ),
  ...templateCreator(
    '2.0',
    CB_CERT_TEMPLATES_FILENAME,
    'cb',
    false,
    'The Certification Template Version 2.0 includes the additional of Business Registered Address details and updated Certification Sites information.'
  ),
  ...templateCreator(
    '2.0',
    MNCB_CERT_TEMPLATES_FILENAME,
    'cb',
    false,
    'The Certification Template Version 2.0 includes the additional of Business Registered Address details and updated Certification Sites information.'
  ),
  ...templateCreator(
    '1.1',
    AB_CERT_TEMPLATES_FILENAME,
    'ab',
    true,
    'The Certification Template Version 1.1 supports adding of legal identifiers, such as Tax ID, Company ID Number, and Business Registration Number, for a Certified Entity.'
  ),
  ...templateCreator(
    '1.1',
    CB_CERT_TEMPLATES_FILENAME,
    'cb',
    true,
    'The Certification Template Version 1.1 supports adding of legal identifiers, such as Tax ID, Company ID Number, and Business Registration Number, for a Certified Entity.'
  ),
  ...templateCreator(
    '1.1',
    MNCB_CERT_TEMPLATES_FILENAME,
    'cb',
    true,
    'The Certification Template Version 1.1 supports adding of legal identifiers, such as Tax ID, Company ID Number, and Business Registration Number, for a Certified Entity.'
  ),
  ...templateCreator(
    '1.0',
    AB_CERT_TEMPLATES_FILENAME,
    'ab',
    false,
    'The Certification Template Version 1.0 is the initial template the system releases.'
  ),
  ...templateCreator(
    '1.0',
    CB_CERT_TEMPLATES_FILENAME,
    'cb',
    false,
    'The Certification Template Version 1.0 is the initial template the system releases.'
  ),
  ...templateCreator(
    '1.0',
    MNCB_CERT_TEMPLATES_FILENAME,
    'cb',
    false,
    'The Certification Template Version 1.0 is the initial template the system releases.'
  ),
];

export const TEMPLATE_VERSIONS = {
  1.0: '1.0',
  1.1: '1.1',
  2.0: '2.0',
  2.1: '2.1',
};

export const TEMPLATE_VERSION_PAYLOAD = {
  [TEMPLATE_VERSIONS[1.0]]: '1',
  [TEMPLATE_VERSIONS[1.1]]: '1.1',
  [TEMPLATE_VERSIONS[2.0]]: '2',
  [TEMPLATE_VERSIONS[2.1]]: '2.1',
};

export const CERT_LATEST_TEMPLATE_VERSION = TEMPLATE_VERSIONS[2.1];

export const getTemplateUrlByName = (name, version = '1.0') => {
  return (
    [...CB_TEMPLATES, ...CERT_TEMPLATES].find(
      (template) => template.name === name && template.version === version
    ) || {}
  ).url;
};

export const certTemplates = (
  accountType = null,
  fileType = null,
  version = null,
  sample = null
) => {
  if (isArray(version)) {
    return version.flatMap((v) => {
      return CERT_TEMPLATES.filter(
        (item) =>
          (!accountType || item.accountType === accountType) &&
          (!fileType || item.fileType === fileType) &&
          (!version || item.version === v) &&
          (isBoolean(sample) ? item.sample === sample : true)
      );
    });
  }

  return CERT_TEMPLATES.filter(
    (item) =>
      (!accountType || item.accountType === accountType) &&
      (!fileType || item.fileType === fileType) &&
      (!version || item.version === version) &&
      (isBoolean(sample) ? item.sample === sample : true)
  );
};

export const cbTemplates = (
  accountType = null,
  fileType = null,
  version = null,
  sample = null,
  instructions = null
) => {
  if (isArray(version)) {
    return version.flatMap((v) => {
      return CB_TEMPLATES.filter(
        (item) =>
          (!accountType || item.accountType === accountType) &&
          (!fileType || item.fileType === fileType) &&
          (!version || item.version === v) &&
          (isBoolean(sample) ? item.sample === sample : true) &&
          (isBoolean(instructions) ? item.instructions === sample : true)
      );
    });
  }

  return CB_TEMPLATES.filter(
    (item) =>
      (!accountType || item.accountType === accountType) &&
      (!fileType || item.fileType === fileType) &&
      (!version || item.version === version) &&
      (isBoolean(sample) ? item.sample === sample : true) &&
      (isBoolean(instructions) ? item.instructions === instructions : true)
  );
};

const ExternalUrl = {
  CB_TEMPLATES,
  CERT_TEMPLATES,
  VIDEO_GUIDE,
  DOC_GUIDE,
  DEV_GUIDE,
};

export default ExternalUrl;
