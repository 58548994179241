import {Badge} from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

import {CONFORMANCE_STATUS_LABELS} from 'configs';

const LabelConformance = (props) => {
  const {status, color, large, className, ...rest} = props;
  const _status = CONFORMANCE_STATUS_LABELS[status] || 'Unknown Status';
  return (
    <Badge
      color={color}
      className={classNames(
        'bg-transparent',
        [`text-${color}`, `border border-${color}`],
        styles['participation-status-badge'],
        className
      )}
      {...rest}
    >
      {_status}
    </Badge>
  );
};

export default LabelConformance;
